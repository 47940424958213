import { combineReducers } from 'redux'
import auth from './slices/authSlice'
import dashboardSlice from './slices/dashboardSlice'
import meetingsSlice from './slices/meetingsSlice'
import theme from './slices/themeSlice'
import user from './slices/userSlice'
import users from './slices/usersSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        user,
        users,
        meetingsSlice,
        dashboard: dashboardSlice,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
