import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AUTH_TOKEN } from "constants/AuthConstant";
import { sendNotification } from "utils/utilities";


export const initialState = {
    subAdminList: [],


    loading: false,
    isCreatingAdminUser: false,
    addAdminUserDrawerStatus: false,
};

export const getSubAdminsData = createAsyncThunk(
    "users/getSubAdminsData",
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/admin/get-sub-admins`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        token: localStorage.getItem(AUTH_TOKEN),
                    },
                }
            );
            if (response.status === 200 && response.data.success === true) {
                const userData = response.data.data;
                return userData;
            } else {
                return rejectWithValue(response.data.message);
            }
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);


export const createAdminUser = createAsyncThunk(
    "users/createAdminUser",
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/admin/create-sub-admin`,
                data,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        token: localStorage.getItem(AUTH_TOKEN),
                    },
                }
            );
            if (response.status === 200 && response.data.success === true) {
                sendNotification({ type: "success", message: response.data.message });
                dispatch(getSubAdminsData())
                return response.data.message;
            } else {
                sendNotification({ type: "error", message: response.data.message });
                return rejectWithValue(response.data.message);
            }
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        handleAdminUserDrawerStatus: (state, action) => {
            state.addAdminUserDrawerStatus = !state.addAdminUserDrawerStatus;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createAdminUser.pending, (state) => {
                state.isCreatingAdminUser = true;
            })
            .addCase(createAdminUser.fulfilled, (state, action) => {
                state.isCreatingAdminUser = false;
                state.addAdminUserDrawerStatus = !state.addAdminUserDrawerStatus
            })
            .addCase(createAdminUser.rejected, (state, action) => {
                state.isCreatingAdminUser = false;
                state.addAdminUserDrawerStatus = !state.addAdminUserDrawerStatus
            }).addCase(getSubAdminsData.pending, (state) => {
                state.loading = true;
            }).addCase(getSubAdminsData.fulfilled, (state, action) => {
                state.loading = false;
                state.subAdminList = action.payload;
            }).addCase(getSubAdminsData.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            });
    },
});


export const { handleAdminUserDrawerStatus } = usersSlice.actions;

export default usersSlice.reducer;