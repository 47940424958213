/* eslint-disable no-unused-vars */
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import React from "react";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() => import("views/auth-views/authentication/login")),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
    roles: ['admin', 'sub-admin']
  },
  {
    key: "agents-management",
    path: `${APP_PREFIX_PATH}/agents-management`,
    component: React.lazy(() => import("views/app-views/pages/user-list")),
    roles: ['admin', 'sub-admin']
  },
  {
    key: "sidenav.user",
    path: `${APP_PREFIX_PATH}/user-management`,
    component: React.lazy(() => import("views/app-views/pages/user-management/index")),
    roles: ['admin']
  },
  // {
  //   key: "meeting-history",
  //   path: `${APP_PREFIX_PATH}/meeting-history`,
  //   component: React.lazy(() => import("views/app-views/meeting-history")),
  // },
  // {
  //   key: "manage-plans",
  //   path: `${APP_PREFIX_PATH}/manage-plans`,
  //   component: React.lazy(() => import("views/app-views/manage-plans")),
  // },
  // {
  //   key: "notifications",
  //   path: `${APP_PREFIX_PATH}/notifications`,
  //   component: React.lazy(() => import("views/app-views/notifications")),
  // },
  // ====================================================================================
];
