/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AUTH_TOKEN } from "constants/AuthConstant";
import { sendNotification } from "utils/utilities";
import { getSubAdminsData } from "./usersSlice";
export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  // token: localStorage.getItem(AUTH_TOKEN) || null,
  isAddingNewUser: false,
  userList: [],
  resetPasswordDrawerStatus: false,
  editUserDrawerStatus: false,
  singleUserData: {},
  isUpdatingUser: false,

  loadingUserDetails: false,
  userDetails: {},
};

export const listAllUsers = createAsyncThunk("user/list", async (data, { rejectWithValue }) => {
  // const { email, password } = data;
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/get-all-users`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          token: localStorage.getItem(AUTH_TOKEN),
        },
      }
    );
    if (response.status === 200 && response.data.success === true) {
      const userData = response.data.data;
      return userData;
    } else {
      return rejectWithValue(response.data.message);
    }
    // const response = await FirebaseService.signInEmailRequest(email, password)
    // if (response.user) {
    // 	const token = response.user.refreshToken;
    // 	localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
    // 	return token;
    // } else {
    // 	return rejectWithValue(response.message?.replace('Firebase: ', ''));
    // }
  } catch (err) {
    return rejectWithValue(err.message || "Error");
  }
});

export const addNewUser = createAsyncThunk("user/add", async (data, { rejectWithValue, dispatch }) => {
  // const { email, password } = data;
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/create-user`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          token: localStorage.getItem(AUTH_TOKEN),
        },
      }
    );
    if (response.status === 200 && response.data.success === true) {
      dispatch(listAllUsers())
      const userData = response.data.data;
      return userData;
    } else {
      sendNotification({ type: "error", message: response.data.message });
      return rejectWithValue(response.data.message);
    }
  } catch (err) {
    return rejectWithValue(err.message || "Error");
  }
});

export const updateUser = createAsyncThunk("user/update", async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/update-user`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          token: localStorage.getItem(AUTH_TOKEN),
        },
      }
    );
    if (response.status === 200 && response.data.success === true) {
      const userData = response.data.data;
      sendNotification({ type: "success", message: response.data.message });
      dispatch(listAllUsers());
      return userData;
    } else {
      return rejectWithValue(response.data.message);
    }
  } catch (err) {
    return rejectWithValue(err.message || "Error");
  }
});


export const deleteUser = createAsyncThunk("user/delete", async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/delete-user`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          token: localStorage.getItem(AUTH_TOKEN),
        },
      }
    );
    if (response.status === 200 && response.data.success === true) {
      const userData = response.data.data;
      sendNotification({ type: "success", message: response.data.message });
      if (data.userType === 0) {
        dispatch(listAllUsers());
      } else {
        dispatch(getSubAdminsData());
      }
      return userData;
    } else {
      return rejectWithValue(response.data.message);
    }
  } catch (err) {
    return rejectWithValue(err.message || "Error");
  }
});

export const resetUserPassword = createAsyncThunk("user/reset", async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/update-agent-password`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          token: localStorage.getItem(AUTH_TOKEN),
        },
      }
    );
    if (response.status === 200 && response.data.success === true) {
      const userData = response.data.data;
      sendNotification({ type: "success", message: response.data.message });
      return userData;
    } else {
      return rejectWithValue(response.data.message);
    }
  } catch (err) {
    return rejectWithValue(err.message || "Error");
  }
});

export const getUserDetails = createAsyncThunk("user/details", async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/get-user-details`,
      { token: localStorage.getItem(AUTH_TOKEN) },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          token: localStorage.getItem(AUTH_TOKEN),
        },
      }
    );
    if (response.status === 200 && response.data.success === true) {
      const userData = response.data.data;

      if (!userData.role) {
        userData.role = 'admin'
      }

      return fulfillWithValue({ userDetails: userData });
    } else {
      return rejectWithValue(response.data.message);
    }
  } catch (err) {
    return rejectWithValue(err.message || "Error");
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = "/";
      state.token = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.redirect = "/";
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
    handleResetPasswordDrawer: (state, action) => {
      state.singleUserData = action.payload;
      state.resetPasswordDrawerStatus = !state.resetPasswordDrawerStatus;
    },
    handleEditUserDrawerStatus: (state, action) => {
      state.singleUserData = action.payload;
      state.editUserDrawerStatus = !state.editUserDrawerStatus;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listAllUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(listAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.userList = action.payload;
      })
      .addCase(listAllUsers.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(addNewUser.pending, (state) => {
        state.isAddingNewUser = true;
      })
      .addCase(addNewUser.fulfilled, (state, action) => {
        state.isAddingNewUser = false;
        // state.userList = action.payload;
      })
      .addCase(addNewUser.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.isAddingNewUser = false;
      })
      .addCase(updateUser.pending, (state) => {
        state.isAddingNewUser = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isAddingNewUser = false;
        state.editUserDrawerStatus = !state.editUserDrawerStatus;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.isAddingNewUser = false;
        state.editUserDrawerStatus = !state.editUserDrawerStatus;
      }).addCase(resetUserPassword.pending, (state) => {
        state.isUpdatingUser = true;
      }).addCase(resetUserPassword.fulfilled, (state, action) => {
        state.isUpdatingUser = false;
        state.resetPasswordDrawerStatus = !state.resetPasswordDrawerStatus;
      }).addCase(resetUserPassword.rejected, (state, action) => {
        state.resetPasswordDrawerStatus = !state.resetPasswordDrawerStatus;
      })
      .addCase(getUserDetails.pending, (state) => {
        state.loadingUserDetails = true;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.loadingUserDetails = false;
        state.userDetails = action.payload.userDetails;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loadingUserDetails = false;
      });
  },
});

export const { authenticated, showAuthMessage, hideAuthMessage, signOutSuccess, showLoading, signInSuccess, handleResetPasswordDrawer, handleEditUserDrawerStatus } = userSlice.actions;

export default userSlice.reducer;
